var styles = {
  "SearchField": "Polaris-TopBar-SearchField",
  "focused": "Polaris-TopBar-SearchField--focused",
  "Input": "Polaris-TopBar-SearchField__Input",
  "Backdrop": "Polaris-TopBar-SearchField__Backdrop",
  "BackdropShowFocusBorder": "Polaris-TopBar-SearchField__BackdropShowFocusBorder",
  "Icon": "Polaris-TopBar-SearchField__Icon",
  "Clear": "Polaris-TopBar-SearchField__Clear"
};

export { styles as default };
