export var csvGenerateRow = function (row, headerKeys, delimiter) {
    return headerKeys
        .map(function (fieldName) {
        return typeof row[fieldName] === "number"
            ? row[fieldName]
            : "\"".concat(String(row[fieldName]).replace(/"/g, '""'), "\"");
    })
        .join(delimiter);
};
export var csvGenerate = function (data, headers, delimiter) {
    var headerKeys = Object.keys(data[0]);
    var columnNames = headers !== null && headers !== void 0 ? headers : headerKeys;
    var csv = data.map(function (row) { return csvGenerateRow(row, headerKeys, delimiter); });
    csv.unshift(columnNames.join(delimiter));
    return csv.join("\r\n");
};
