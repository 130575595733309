var styles = {
  "RangeSlider": "Polaris-RangeSlider-DualThumb__RangeSlider",
  "DualThumb": "Polaris-RangeSlider-DualThumb",
  "TrackWrapper": "Polaris-RangeSlider-DualThumb__TrackWrapper",
  "disabled": "Polaris-RangeSlider-DualThumb--disabled",
  "Track": "Polaris-RangeSlider-DualThumb__Track",
  "error": "Polaris-RangeSlider-DualThumb--error",
  "Track--dashed": "Polaris-RangeSlider-DualThumb--trackDashed",
  "Thumbs": "Polaris-RangeSlider-DualThumb__Thumbs",
  "Prefix": "Polaris-RangeSlider-DualThumb__Prefix",
  "Suffix": "Polaris-RangeSlider-DualThumb__Suffix",
  "Output": "Polaris-RangeSlider-DualThumb__Output",
  "OutputBubble": "Polaris-RangeSlider-DualThumb__OutputBubble"
};

export { styles as default };
