var styles = {
  "Filters": "Polaris-Filters",
  "FiltersContainer": "Polaris-Filters__FiltersContainer",
  "FiltersContainerHeader": "Polaris-Filters__FiltersContainerHeader",
  "FiltersDesktopContainerContent": "Polaris-Filters__FiltersDesktopContainerContent",
  "FiltersMobileContainerContent": "Polaris-Filters__FiltersMobileContainerContent",
  "FiltersContainerFooter": "Polaris-Filters__FiltersContainerFooter",
  "FiltersMobileContainerFooter": "Polaris-Filters__FiltersMobileContainerFooter",
  "EmptyFooterState": "Polaris-Filters__EmptyFooterState",
  "FilterTriggerContainer": "Polaris-Filters__FilterTriggerContainer",
  "FilterTrigger": "Polaris-Filters__FilterTrigger",
  "FilterTriggerTitle": "Polaris-Filters__FilterTriggerTitle",
  "AppliedFilterBadgeContainer": "Polaris-Filters__AppliedFilterBadgeContainer",
  "open": "Polaris-Filters--open",
  "FilterTriggerLabelContainer": "Polaris-Filters__FilterTriggerLabelContainer",
  "first": "Polaris-Filters--first",
  "last": "Polaris-Filters--last",
  "FilterNodeContainer": "Polaris-Filters__FilterNodeContainer",
  "SearchIcon": "Polaris-Filters__SearchIcon",
  "Backdrop": "Polaris-Filters__Backdrop",
  "HelpText": "Polaris-Filters__HelpText",
  "TagsContainer": "Polaris-Filters__TagsContainer"
};

export { styles as default };
