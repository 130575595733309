let StatusValue;

(function (StatusValue) {
  StatusValue["Info"] = "info";
  StatusValue["Success"] = "success";
  StatusValue["Warning"] = "warning";
  StatusValue["Critical"] = "critical";
  StatusValue["Attention"] = "attention";
  StatusValue["New"] = "new";
})(StatusValue || (StatusValue = {}));

let ProgressValue;

(function (ProgressValue) {
  ProgressValue["Incomplete"] = "incomplete";
  ProgressValue["PartiallyComplete"] = "partiallyComplete";
  ProgressValue["Complete"] = "complete";
})(ProgressValue || (ProgressValue = {}));

export { ProgressValue, StatusValue };
