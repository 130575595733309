export { createMetadata, metadata } from '../src/metadata.mjs';
export { createVar, getCustomPropertyNames, getKeyframeNames, getMediaConditions, getUnit, isKeyOf, rem, removeMetadata, toEm, toPx, toRem, tokensToRems } from '../src/utilities.mjs';

var breakpoints = {
  "breakpoints-xs": "0rem",
  "breakpoints-sm": "30.625rem",
  "breakpoints-md": "48rem",
  "breakpoints-lg": "65rem",
  "breakpoints-xl": "90rem"
};
var border = {
  "border-radius-05": "0.125rem",
  "border-radius-1": "0.25rem",
  "border-radius-2": "0.5rem",
  "border-radius-3": "0.75rem",
  "border-radius-4": "1rem",
  "border-radius-5": "1.25rem",
  "border-radius-6": "1.875rem",
  "border-radius-full": "624.9375rem",
  "border-width-1": "0.0625rem",
  "border-width-2": "0.125rem",
  "border-width-3": "0.1875rem",
  "border-width-4": "0.25rem",
  "border-width-5": "0.3125rem"
};
var color = {
  "color-bg-inverse": "rgba(31, 33, 36, 1)",
  "color-bg-inset-strong": "rgba(97, 106, 117, 1)",
  "color-bg-inverse-hover": "rgba(97, 106, 117, 1)",
  "color-bg-inverse-active": "rgba(135, 144, 155, 1)",
  "color-bg-strong-hover": "rgba(202, 206, 211, 1)",
  "color-bg-strong-active": "rgba(202, 206, 211, 1)",
  "color-bg-strong": "rgba(221, 224, 228, 1)",
  "color-bg-subdued-active": "rgba(235, 236, 239, 1)",
  "color-bg-disabled": "rgba(235, 236, 239, 1)",
  "color-bg-interactive-disabled": "rgba(235, 236, 239, 1)",
  "color-bg-app": "rgba(241, 242, 244, 1)",
  "color-bg-app-active": "rgba(221, 224, 228, 1)",
  "color-bg-app-hover": "rgba(235, 236, 239, 1)",
  "color-bg-app-selected": "rgba(235, 236, 239, 1)",
  "color-bg-active": "rgba(235, 236, 239, 1)",
  "color-bg-subdued-hover": "rgba(241, 242, 244, 1)",
  "color-bg-inset": "rgba(241, 242, 244, 1)",
  "color-bg-hover": "rgba(241, 242, 244, 1)",
  "color-bg-subdued": "rgba(249, 250, 251, 1)",
  "color-bg-input": "rgba(255, 255, 255, 1)",
  "color-bg": "rgba(255, 255, 255, 1)",
  "color-bg-primary-active": "rgba(12, 59, 47, 1)",
  "color-bg-primary-hover": "rgba(18, 84, 67, 1)",
  "color-bg-primary": "rgba(0, 122, 92, 1)",
  "color-bg-success-strong": "rgba(22, 166, 121, 1)",
  "color-bg-success": "rgba(161, 237, 208, 1)",
  "color-bg-primary-subdued-active": "rgba(192, 242, 221, 1)",
  "color-bg-success-subdued-active": "rgba(192, 242, 221, 1)",
  "color-bg-success-subdued": "rgba(224, 248, 238, 1)",
  "color-bg-primary-subdued-hover": "rgba(224, 248, 238, 1)",
  "color-bg-success-subdued-hover": "rgba(240, 253, 248, 1)",
  "color-bg-primary-subdued": "rgba(240, 253, 248, 1)",
  "color-bg-primary-subdued-selected": "rgba(240, 253, 248, 1)",
  "color-bg-critical-strong-active": "rgba(115, 24, 7, 1)",
  "color-bg-critical-strong-hover": "rgba(159, 32, 10, 1)",
  "color-bg-critical-strong": "rgba(197, 40, 12, 1)",
  "color-bg-critical-subdued-active": "rgba(251, 197, 188, 1)",
  "color-bg-critical": "rgba(251, 197, 188, 1)",
  "color-bg-critical-subdued": "rgba(253, 226, 221, 1)",
  "color-bg-critical-subdued-hover": "rgba(254, 243, 241, 1)",
  "color-bg-caution-strong": "rgba(216, 155, 13, 1)",
  "color-bg-caution": "rgba(248, 217, 144, 1)",
  "color-bg-caution-subdued-active": "rgba(250, 229, 178, 1)",
  "color-bg-caution-subdued": "rgba(252, 240, 212, 1)",
  "color-bg-caution-subdued-hover": "rgba(254, 248, 236, 1)",
  "color-bg-info-strong": "rgba(42, 172, 187, 1)",
  "color-bg-info-subdued-active": "rgba(184, 233, 239, 1)",
  "color-bg-info": "rgba(184, 233, 239, 1)",
  "color-bg-info-subdued": "rgba(222, 245, 247, 1)",
  "color-bg-info-subdued-hover": "rgba(238, 250, 251, 1)",
  "color-bg-interactive-active": "rgba(14, 53, 108, 1)",
  "color-bg-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-bg-interactive": "rgba(36, 99, 188, 1)",
  "color-bg-interactive-subdued-active": "rgba(187, 212, 247, 1)",
  "color-bg-interactive-subdued-hover": "rgba(232, 240, 253, 1)",
  "color-bg-interactive-subdued": "rgba(240, 245, 253, 1)",
  "color-bg-interactive-selected": "rgba(240, 245, 253, 1)",
  "color-bg-warning": "rgba(250, 201, 168, 1)",
  "color-bg-magic-strong": "rgba(121, 69, 227, 1)",
  "color-bg-magic-hover": "rgba(226, 214, 250, 1)",
  "color-bg-magic-active": "rgba(203, 180, 248, 1)",
  "color-bg-magic": "rgba(236, 227, 253, 1)",
  "color-bg-magic-subdued-hover": "rgba(236, 227, 253, 1)",
  "color-bg-magic-subdued-active": "rgba(226, 214, 250, 1)",
  "color-bg-magic-subdued": "rgba(242, 237, 253, 1)",
  "color-border-input-hover": "rgba(97, 106, 117, 1)",
  "color-border-inverse": "rgba(97, 106, 117, 1)",
  "color-border-strong-hover": "rgba(135, 144, 155, 1)",
  "color-border-input": "rgba(171, 177, 186, 1)",
  "color-border-hover": "rgba(171, 177, 186, 1)",
  "color-border-strong": "rgba(171, 177, 186, 1)",
  "color-border": "rgba(202, 206, 211, 1)",
  "color-border-disabled": "rgba(221, 224, 228, 1)",
  "color-border-subdued": "rgba(221, 224, 228, 1)",
  "color-border-interactive-disabled": "rgba(221, 224, 228, 1)",
  "color-border-primary": "rgba(0, 122, 92, 1)",
  "color-border-success": "rgba(22, 166, 121, 1)",
  "color-border-success-subdued": "rgba(80, 220, 169, 1)",
  "color-border-critical-active": "rgba(67, 14, 4, 1)",
  "color-border-critical-hover": "rgba(115, 24, 7, 1)",
  "color-border-critical": "rgba(197, 40, 12, 1)",
  "color-border-critical-subdued": "rgba(245, 107, 82, 1)",
  "color-border-caution": "rgba(216, 155, 13, 1)",
  "color-border-caution-subdued": "rgba(245, 196, 82, 1)",
  "color-border-info": "rgba(59, 195, 211, 1)",
  "color-border-info-subdued": "rgba(109, 211, 222, 1)",
  "color-border-interactive-active": "rgba(14, 53, 108, 1)",
  "color-border-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-border-interactive": "rgba(62, 125, 213, 1)",
  "color-border-interactive-focus": "rgba(62, 125, 213, 1)",
  "color-border-interactive-subdued": "rgba(187, 212, 247, 1)",
  "color-border-magic-strong": "rgba(121, 69, 227, 1)",
  "color-border-magic": "rgba(173, 139, 241, 1)",
  "color-icon-hover": "rgba(31, 33, 36, 1)",
  "color-icon": "rgba(97, 106, 117, 1)",
  "color-icon-active": "rgba(31, 33, 36, 1)",
  "color-icon-subdued": "rgba(135, 144, 155, 1)",
  "color-icon-disabled": "rgba(171, 177, 186, 1)",
  "color-icon-interactive-disabled": "rgba(171, 177, 186, 1)",
  "color-icon-inverse": "rgba(221, 224, 228, 1)",
  "color-icon-on-color": "rgba(255, 255, 255, 1)",
  "color-icon-primary": "rgba(0, 122, 92, 1)",
  "color-icon-success": "rgba(22, 166, 121, 1)",
  "color-icon-critical": "rgba(197, 40, 12, 1)",
  "color-icon-caution": "rgba(183, 126, 11, 1)",
  "color-icon-info": "rgba(42, 172, 187, 1)",
  "color-icon-warning": "rgba(242, 117, 34, 1)",
  "color-icon-interactive-active": "rgba(14, 53, 108, 1)",
  "color-icon-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-icon-interactive": "rgba(36, 99, 188, 1)",
  "color-icon-interactive-inverse": "rgba(102, 153, 225, 1)",
  "color-icon-magic": "rgba(121, 69, 227, 1)",
  "color-text": "rgba(31, 33, 36, 1)",
  "color-text-subdued": "rgba(97, 106, 117, 1)",
  "color-text-disabled": "rgba(135, 144, 155, 1)",
  "color-text-interactive-disabled": "rgba(135, 144, 155, 1)",
  "color-text-inverse-subdued": "rgba(171, 177, 186, 1)",
  "color-text-inverse": "rgba(241, 242, 244, 1)",
  "color-text-on-color": "rgba(255, 255, 255, 1)",
  "color-text-success-strong": "rgba(12, 59, 47, 1)",
  "color-text-success": "rgba(0, 122, 92, 1)",
  "color-text-primary": "rgba(0, 122, 92, 1)",
  "color-text-primary-hover": "rgba(18, 84, 67, 1)",
  "color-text-critical-strong": "rgba(67, 14, 4, 1)",
  "color-text-critical-active": "rgba(115, 24, 7, 1)",
  "color-text-critical": "rgba(197, 40, 12, 1)",
  "color-text-caution-strong": "rgba(77, 46, 5, 1)",
  "color-text-caution": "rgba(135, 92, 8, 1)",
  "color-text-info-strong": "rgba(16, 65, 71, 1)",
  "color-text-info": "rgba(32, 130, 141, 1)",
  "color-text-warning-strong": "rgba(77, 36, 5, 1)",
  "color-text-interactive-active": "rgba(14, 53, 108, 1)",
  "color-text-interactive-hover": "rgba(20, 73, 149, 1)",
  "color-text-interactive": "rgba(36, 99, 188, 1)",
  "color-text-interactive-inverse": "rgba(102, 153, 225, 1)",
  "color-text-magic-strong": "rgba(49, 13, 120, 1)",
  "color-text-magic": "rgba(90, 36, 205, 1)"
};
var colors = {
  "background": "rgba(246, 246, 247, 1)",
  "background-hovered": "rgba(241, 242, 243, 1)",
  "background-pressed": "rgba(237, 238, 239, 1)",
  "background-selected": "rgba(237, 238, 239, 1)",
  "surface": "rgba(255, 255, 255, 1)",
  "surface-dark": "rgba(32, 33, 35, 1)",
  "surface-neutral": "rgba(228, 229, 231, 1)",
  "surface-neutral-hovered": "rgba(219, 221, 223, 1)",
  "surface-neutral-pressed": "rgba(201, 204, 208, 1)",
  "surface-neutral-disabled": "rgba(241, 242, 243, 1)",
  "surface-neutral-subdued": "rgba(246, 246, 247, 1)",
  "surface-neutral-subdued-dark": "rgba(68, 71, 74, 1)",
  "surface-subdued": "rgba(250, 251, 251, 1)",
  "surface-disabled": "rgba(250, 251, 251, 1)",
  "surface-hovered": "rgba(246, 246, 247, 1)",
  "surface-hovered-dark": "rgba(47, 49, 51, 1)",
  "surface-pressed": "rgba(241, 242, 243, 1)",
  "surface-pressed-dark": "rgba(62, 64, 67, 1)",
  "surface-depressed": "rgba(237, 238, 239, 1)",
  "surface-search-field": "rgba(241, 242, 243, 1)",
  "surface-search-field-dark": "rgba(47, 49, 51, 1)",
  "backdrop": "rgba(0, 0, 0, 0.5)",
  "overlay": "rgba(255, 255, 255, 0.5)",
  "shadow-color-picker": "rgba(0, 0, 0, 0.5)",
  "shadow-color-picker-dragger": "rgba(33, 43, 54, 0.32)",
  "hint-from-direct-light": "rgba(0, 0, 0, 0.15)",
  "border": "rgba(140, 145, 150, 1)",
  "border-on-dark": "rgba(80, 83, 86, 1)",
  "border-neutral-subdued": "rgba(186, 191, 195, 1)",
  "border-hovered": "rgba(153, 158, 164, 1)",
  "border-disabled": "rgba(210, 213, 216, 1)",
  "border-subdued": "rgba(201, 204, 207, 1)",
  "border-depressed": "rgba(87, 89, 89, 1)",
  "border-shadow": "rgba(174, 180, 185, 1)",
  "border-shadow-subdued": "rgba(186, 191, 196, 1)",
  "divider": "rgba(225, 227, 229, 1)",
  "divider-dark": "rgba(69, 71, 73, 1)",
  "icon": "rgba(92, 95, 98, 1)",
  "icon-on-dark": "rgba(166, 172, 178, 1)",
  "icon-hovered": "rgba(26, 28, 29, 1)",
  "icon-pressed": "rgba(68, 71, 74, 1)",
  "icon-disabled": "rgba(186, 190, 195, 1)",
  "icon-subdued": "rgba(140, 145, 150, 1)",
  "text": "rgba(32, 34, 35, 1)",
  "text-on-dark": "rgba(227, 229, 231, 1)",
  "text-disabled": "rgba(140, 145, 150, 1)",
  "text-subdued": "rgba(109, 113, 117, 1)",
  "text-subdued-on-dark": "rgba(153, 159, 164, 1)",
  "interactive": "rgba(44, 110, 203, 1)",
  "interactive-on-dark": "rgba(54, 163, 255, 1)",
  "interactive-disabled": "rgba(189, 193, 204, 1)",
  "interactive-hovered": "rgba(31, 81, 153, 1)",
  "interactive-pressed": "rgba(16, 50, 98, 1)",
  "interactive-pressed-on-dark": "rgba(136, 188, 255, 1)",
  "focused": "rgba(69, 143, 255, 1)",
  "surface-selected": "rgba(242, 247, 254, 1)",
  "surface-selected-hovered": "rgba(237, 244, 254, 1)",
  "surface-selected-pressed": "rgba(229, 239, 253, 1)",
  "icon-on-interactive": "rgba(255, 255, 255, 1)",
  "text-on-interactive": "rgba(255, 255, 255, 1)",
  "action-secondary": "rgba(255, 255, 255, 1)",
  "action-secondary-disabled": "rgba(255, 255, 255, 1)",
  "action-secondary-hovered": "rgba(246, 246, 247, 1)",
  "action-secondary-hovered-dark": "rgba(84, 87, 91, 1)",
  "action-secondary-pressed": "rgba(241, 242, 243, 1)",
  "action-secondary-pressed-dark": "rgba(96, 100, 103, 1)",
  "action-secondary-depressed": "rgba(109, 113, 117, 1)",
  "action-primary": "rgba(0, 128, 96, 1)",
  "action-primary-disabled": "rgba(241, 241, 241, 1)",
  "action-primary-hovered": "rgba(0, 110, 82, 1)",
  "action-primary-pressed": "rgba(0, 94, 70, 1)",
  "action-primary-depressed": "rgba(0, 61, 44, 1)",
  "icon-on-primary": "rgba(255, 255, 255, 1)",
  "text-on-primary": "rgba(255, 255, 255, 1)",
  "text-primary": "rgba(0, 123, 92, 1)",
  "text-primary-hovered": "rgba(0, 108, 80, 1)",
  "text-primary-pressed": "rgba(0, 92, 68, 1)",
  "surface-primary-selected": "rgba(241, 248, 245, 1)",
  "surface-primary-selected-hovered": "rgba(179, 208, 195, 1)",
  "surface-primary-selected-pressed": "rgba(162, 188, 176, 1)",
  "border-critical": "rgba(253, 87, 73, 1)",
  "border-critical-subdued": "rgba(224, 179, 178, 1)",
  "border-critical-disabled": "rgba(255, 167, 163, 1)",
  "icon-critical": "rgba(215, 44, 13, 1)",
  "surface-critical": "rgba(254, 211, 209, 1)",
  "surface-critical-subdued": "rgba(255, 244, 244, 1)",
  "surface-critical-subdued-hovered": "rgba(255, 240, 240, 1)",
  "surface-critical-subdued-pressed": "rgba(255, 233, 232, 1)",
  "surface-critical-subdued-depressed": "rgba(254, 188, 185, 1)",
  "text-critical": "rgba(215, 44, 13, 1)",
  "action-critical": "rgba(216, 44, 13, 1)",
  "action-critical-disabled": "rgba(241, 241, 241, 1)",
  "action-critical-hovered": "rgba(188, 34, 0, 1)",
  "action-critical-pressed": "rgba(162, 27, 0, 1)",
  "action-critical-depressed": "rgba(108, 15, 0, 1)",
  "icon-on-critical": "rgba(255, 255, 255, 1)",
  "text-on-critical": "rgba(255, 255, 255, 1)",
  "interactive-critical": "rgba(216, 44, 13, 1)",
  "interactive-critical-disabled": "rgba(253, 147, 141, 1)",
  "interactive-critical-hovered": "rgba(205, 41, 12, 1)",
  "interactive-critical-pressed": "rgba(103, 15, 3, 1)",
  "border-warning": "rgba(185, 137, 0, 1)",
  "border-warning-subdued": "rgba(225, 184, 120, 1)",
  "icon-warning": "rgba(185, 137, 0, 1)",
  "surface-warning": "rgba(255, 215, 157, 1)",
  "surface-warning-subdued": "rgba(255, 245, 234, 1)",
  "surface-warning-subdued-hovered": "rgba(255, 242, 226, 1)",
  "surface-warning-subdued-pressed": "rgba(255, 235, 211, 1)",
  "text-warning": "rgba(145, 106, 0, 1)",
  "border-highlight": "rgba(68, 157, 167, 1)",
  "border-highlight-subdued": "rgba(152, 198, 205, 1)",
  "icon-highlight": "rgba(0, 160, 172, 1)",
  "surface-highlight": "rgba(164, 232, 242, 1)",
  "surface-highlight-subdued": "rgba(235, 249, 252, 1)",
  "surface-highlight-subdued-hovered": "rgba(228, 247, 250, 1)",
  "surface-highlight-subdued-pressed": "rgba(213, 243, 248, 1)",
  "text-highlight": "rgba(52, 124, 132, 1)",
  "border-success": "rgba(0, 164, 124, 1)",
  "border-success-subdued": "rgba(149, 201, 180, 1)",
  "icon-success": "rgba(0, 127, 95, 1)",
  "surface-success": "rgba(174, 233, 209, 1)",
  "surface-success-subdued": "rgba(241, 248, 245, 1)",
  "surface-success-subdued-hovered": "rgba(236, 246, 241, 1)",
  "surface-success-subdued-pressed": "rgba(226, 241, 234, 1)",
  "text-success": "rgba(0, 128, 96, 1)",
  "icon-attention": "rgba(138, 97, 22, 1)",
  "surface-attention": "rgba(255, 234, 138, 1)",
  "decorative-one-icon": "rgba(126, 87, 0, 1)",
  "decorative-one-surface": "rgba(255, 201, 107, 1)",
  "decorative-one-text": "rgba(61, 40, 0, 1)",
  "decorative-two-icon": "rgba(175, 41, 78, 1)",
  "decorative-two-surface": "rgba(255, 196, 176, 1)",
  "decorative-two-text": "rgba(73, 11, 28, 1)",
  "decorative-three-icon": "rgba(0, 109, 65, 1)",
  "decorative-three-surface": "rgba(146, 230, 181, 1)",
  "decorative-three-text": "rgba(0, 47, 25, 1)",
  "decorative-four-icon": "rgba(0, 106, 104, 1)",
  "decorative-four-surface": "rgba(145, 224, 214, 1)",
  "decorative-four-text": "rgba(0, 45, 45, 1)",
  "decorative-five-icon": "rgba(174, 43, 76, 1)",
  "decorative-five-surface": "rgba(253, 201, 208, 1)",
  "decorative-five-text": "rgba(79, 14, 31, 1)"
};
var depth = {
  "shadow-transparent": "0 0 0 0 transparent",
  "shadow-faint": "0 1px 0 0 rgba(22, 29, 37, 0.05)",
  "shadow-base": "0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)",
  "shadow-deep": "0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08)",
  "shadow-button": "0 1px 0 rgba(0, 0, 0, 0.05)",
  "shadow-top-bar": "0 2px 2px -1px rgba(0, 0, 0, 0.15)",
  "shadow-card": "0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15)",
  "shadow-popover": "0 3px 6px -3px rgba(23, 24, 24, 0.08), 0 8px 20px -4px rgba(23, 24, 24, 0.12)",
  "shadow-layer": "0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08)",
  "shadow-modal": "0 26px 80px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.2)",
  "shadows-inset-button": "inset 0 -1px 0 rgba(0, 0, 0, 0.2)",
  "shadows-inset-button-pressed": "inset 0 1px 0 rgba(0, 0, 0, 0.15)"
};
var font = {
  "font-family-sans": "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
  "font-family-mono": "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace",
  "font-size-75": "0.75rem",
  "font-size-100": "0.875rem",
  "font-size-200": "1rem",
  "font-size-300": "1.25rem",
  "font-size-400": "1.5rem",
  "font-size-500": "1.75rem",
  "font-size-600": "2rem",
  "font-size-700": "2.5rem",
  "font-weight-regular": "400",
  "font-weight-medium": "500",
  "font-weight-semibold": "600",
  "font-weight-bold": "700",
  "font-line-height-1": "1rem",
  "font-line-height-2": "1.25rem",
  "font-line-height-3": "1.5rem",
  "font-line-height-4": "1.75rem",
  "font-line-height-5": "2rem",
  "font-line-height-6": "2.5rem",
  "font-line-height-7": "3rem"
};
var legacy = {
  "override-loading-z-index": "514",
  "choice-size": "1.25rem",
  "icon-size-small": "0.5rem",
  "icon-size-medium": "1.25rem",
  "choice-margin": "0.0625rem",
  "control-border-width": "0.125rem",
  "banner-border-default": "inset 0 0.0625rem 0 0 var(--p-color-border-strong), inset 0 0 0 0.0625rem var(--p-color-border-strong)",
  "banner-border-success": "inset 0 0.0625rem 0 0 var(--p-color-border-success-subdued), inset 0 0 0 0.0625rem var(--p-color-border-success-subdued)",
  "banner-border-highlight": "inset 0 0.0625rem 0 0 var(--p-color-border-info-subdued), inset 0 0 0 0.0625rem var(--p-color-border-info-subdued)",
  "banner-border-warning": "inset 0 0.0625rem 0 0 var(--p-color-border-caution-subdued), inset 0 0 0 0.0625rem var(--p-color-border-caution-subdued)",
  "banner-border-critical": "inset 0 0.0625rem 0 0 var(--p-color-border-critical-subdued), inset 0 0 0 0.0625rem var(--p-color-border-critical-subdued)",
  "thin-border-subdued": "0.0625rem solid var(--p-color-border-subdued)",
  "text-field-spinner-offset": "0.125rem",
  "text-field-focus-ring-offset": "-0.25rem",
  "button-group-item-spacing": "-0.0625rem",
  "range-slider-thumb-size-base": "1rem",
  "range-slider-thumb-size-active": "1.5rem",
  "frame-offset": "0rem"
};
var motion = {
  "duration-0": "0ms",
  "duration-50": "50ms",
  "duration-100": "100ms",
  "duration-150": "150ms",
  "duration-200": "200ms",
  "duration-250": "250ms",
  "duration-300": "300ms",
  "duration-350": "350ms",
  "duration-400": "400ms",
  "duration-450": "450ms",
  "duration-500": "500ms",
  "duration-5000": "5000ms",
  "ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
  "ease-in": "cubic-bezier(0.42, 0, 1, 1)",
  "ease-out": "cubic-bezier(0, 0, 0.58, 1)",
  "ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
  "linear": "cubic-bezier(0, 0, 1, 1)",
  "keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
  "keyframes-fade-in": "{ to { opacity: 1 } }",
  "keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
  "keyframes-spin": "{ to { transform: rotate(1turn) } }",
  "keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
  "keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }",
  "motion-duration-0": "0ms",
  "motion-duration-50": "50ms",
  "motion-duration-100": "100ms",
  "motion-duration-150": "150ms",
  "motion-duration-200": "200ms",
  "motion-duration-250": "250ms",
  "motion-duration-300": "300ms",
  "motion-duration-350": "350ms",
  "motion-duration-400": "400ms",
  "motion-duration-450": "450ms",
  "motion-duration-500": "500ms",
  "motion-duration-5000": "5000ms",
  "motion-ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
  "motion-ease-in": "cubic-bezier(0.42, 0, 1, 1)",
  "motion-ease-out": "cubic-bezier(0, 0, 0.58, 1)",
  "motion-ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
  "motion-linear": "cubic-bezier(0, 0, 1, 1)",
  "motion-keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
  "motion-keyframes-fade-in": "{ to { opacity: 1 } }",
  "motion-keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
  "motion-keyframes-spin": "{ to { transform: rotate(1turn) } }",
  "motion-keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
  "motion-keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }"
};
var shadow = {
  "shadow-inset-lg": "inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18)",
  "shadow-inset-md": "inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32)",
  "shadow-inset-sm": "inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56)",
  "shadow-none": "none",
  "shadow-xs": "0rem 0rem 0.125rem rgba(31, 33, 36, 0.24)",
  "shadow-sm": "0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1)",
  "shadow-md": "0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05)",
  "shadow-lg": "0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2), 0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05)",
  "shadow-xl": "0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08), 0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15)",
  "shadow-2xl": "0rem 2rem 2rem rgba(31, 33, 36, 0.15), 0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16)"
};
var shape = {
  "border-radius-05": "0.125rem",
  "border-radius-1": "0.25rem",
  "border-radius-2": "0.5rem",
  "border-radius-3": "0.75rem",
  "border-radius-4": "1rem",
  "border-radius-5": "1.25rem",
  "border-radius-6": "1.875rem",
  "border-radius-full": "624.9375rem",
  "border-radius-base": "0.1875rem",
  "border-radius-large": "0.375rem",
  "border-radius-half": "50%",
  "border-width-1": "0.0625rem",
  "border-width-2": "0.125rem",
  "border-width-3": "0.1875rem",
  "border-width-4": "0.25rem",
  "border-width-5": "0.3125rem",
  "border-base": "var(--p-border-width-1) solid var(--p-color-border-subdued)",
  "border-dark": "var(--p-border-width-1) solid var(--p-color-border)",
  "border-transparent": "var(--p-border-width-1) solid transparent",
  "border-divider": "var(--p-border-width-1) solid var(--p-color-border-subdued)",
  "border-divider-on-dark": "var(--p-border-width-1) solid var(--p-color-border-inverse)"
};
var spacing = {
  "space-0": "0",
  "space-025": "0.0625rem",
  "space-05": "0.125rem",
  "space-1": "0.25rem",
  "space-2": "0.5rem",
  "space-3": "0.75rem",
  "space-4": "1rem",
  "space-5": "1.25rem",
  "space-6": "1.5rem",
  "space-8": "2rem",
  "space-10": "2.5rem",
  "space-12": "3rem",
  "space-16": "4rem",
  "space-20": "5rem",
  "space-24": "6rem",
  "space-28": "7rem",
  "space-32": "8rem"
};
var space = {
  "space-0": "0",
  "space-025": "0.0625rem",
  "space-05": "0.125rem",
  "space-1": "0.25rem",
  "space-2": "0.5rem",
  "space-3": "0.75rem",
  "space-4": "1rem",
  "space-5": "1.25rem",
  "space-6": "1.5rem",
  "space-8": "2rem",
  "space-10": "2.5rem",
  "space-12": "3rem",
  "space-16": "4rem",
  "space-20": "5rem",
  "space-24": "6rem",
  "space-28": "7rem",
  "space-32": "8rem"
};
var zIndex = {
  "z-index-1": "100",
  "z-index-2": "400",
  "z-index-3": "510",
  "z-index-4": "512",
  "z-index-5": "513",
  "z-index-6": "514",
  "z-index-7": "515",
  "z-index-8": "516",
  "z-index-9": "517",
  "z-index-10": "518",
  "z-index-11": "519",
  "z-index-12": "520",
  "z-1": "100",
  "z-2": "400",
  "z-3": "510",
  "z-4": "512",
  "z-5": "513",
  "z-6": "514",
  "z-7": "515",
  "z-8": "516",
  "z-9": "517",
  "z-10": "518",
  "z-11": "519",
  "z-12": "520"
};
var tokens = {
  "breakpoints": {
    "breakpoints-xs": "0rem",
    "breakpoints-sm": "30.625rem",
    "breakpoints-md": "48rem",
    "breakpoints-lg": "65rem",
    "breakpoints-xl": "90rem"
  },
  "border": {
    "border-radius-05": "0.125rem",
    "border-radius-1": "0.25rem",
    "border-radius-2": "0.5rem",
    "border-radius-3": "0.75rem",
    "border-radius-4": "1rem",
    "border-radius-5": "1.25rem",
    "border-radius-6": "1.875rem",
    "border-radius-full": "624.9375rem",
    "border-width-1": "0.0625rem",
    "border-width-2": "0.125rem",
    "border-width-3": "0.1875rem",
    "border-width-4": "0.25rem",
    "border-width-5": "0.3125rem"
  },
  "color": {
    "color-bg-inverse": "rgba(31, 33, 36, 1)",
    "color-bg-inset-strong": "rgba(97, 106, 117, 1)",
    "color-bg-inverse-hover": "rgba(97, 106, 117, 1)",
    "color-bg-inverse-active": "rgba(135, 144, 155, 1)",
    "color-bg-strong-hover": "rgba(202, 206, 211, 1)",
    "color-bg-strong-active": "rgba(202, 206, 211, 1)",
    "color-bg-strong": "rgba(221, 224, 228, 1)",
    "color-bg-subdued-active": "rgba(235, 236, 239, 1)",
    "color-bg-disabled": "rgba(235, 236, 239, 1)",
    "color-bg-interactive-disabled": "rgba(235, 236, 239, 1)",
    "color-bg-app": "rgba(241, 242, 244, 1)",
    "color-bg-app-active": "rgba(221, 224, 228, 1)",
    "color-bg-app-hover": "rgba(235, 236, 239, 1)",
    "color-bg-app-selected": "rgba(235, 236, 239, 1)",
    "color-bg-active": "rgba(235, 236, 239, 1)",
    "color-bg-subdued-hover": "rgba(241, 242, 244, 1)",
    "color-bg-inset": "rgba(241, 242, 244, 1)",
    "color-bg-hover": "rgba(241, 242, 244, 1)",
    "color-bg-subdued": "rgba(249, 250, 251, 1)",
    "color-bg-input": "rgba(255, 255, 255, 1)",
    "color-bg": "rgba(255, 255, 255, 1)",
    "color-bg-primary-active": "rgba(12, 59, 47, 1)",
    "color-bg-primary-hover": "rgba(18, 84, 67, 1)",
    "color-bg-primary": "rgba(0, 122, 92, 1)",
    "color-bg-success-strong": "rgba(22, 166, 121, 1)",
    "color-bg-success": "rgba(161, 237, 208, 1)",
    "color-bg-primary-subdued-active": "rgba(192, 242, 221, 1)",
    "color-bg-success-subdued-active": "rgba(192, 242, 221, 1)",
    "color-bg-success-subdued": "rgba(224, 248, 238, 1)",
    "color-bg-primary-subdued-hover": "rgba(224, 248, 238, 1)",
    "color-bg-success-subdued-hover": "rgba(240, 253, 248, 1)",
    "color-bg-primary-subdued": "rgba(240, 253, 248, 1)",
    "color-bg-primary-subdued-selected": "rgba(240, 253, 248, 1)",
    "color-bg-critical-strong-active": "rgba(115, 24, 7, 1)",
    "color-bg-critical-strong-hover": "rgba(159, 32, 10, 1)",
    "color-bg-critical-strong": "rgba(197, 40, 12, 1)",
    "color-bg-critical-subdued-active": "rgba(251, 197, 188, 1)",
    "color-bg-critical": "rgba(251, 197, 188, 1)",
    "color-bg-critical-subdued": "rgba(253, 226, 221, 1)",
    "color-bg-critical-subdued-hover": "rgba(254, 243, 241, 1)",
    "color-bg-caution-strong": "rgba(216, 155, 13, 1)",
    "color-bg-caution": "rgba(248, 217, 144, 1)",
    "color-bg-caution-subdued-active": "rgba(250, 229, 178, 1)",
    "color-bg-caution-subdued": "rgba(252, 240, 212, 1)",
    "color-bg-caution-subdued-hover": "rgba(254, 248, 236, 1)",
    "color-bg-info-strong": "rgba(42, 172, 187, 1)",
    "color-bg-info-subdued-active": "rgba(184, 233, 239, 1)",
    "color-bg-info": "rgba(184, 233, 239, 1)",
    "color-bg-info-subdued": "rgba(222, 245, 247, 1)",
    "color-bg-info-subdued-hover": "rgba(238, 250, 251, 1)",
    "color-bg-interactive-active": "rgba(14, 53, 108, 1)",
    "color-bg-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-bg-interactive": "rgba(36, 99, 188, 1)",
    "color-bg-interactive-subdued-active": "rgba(187, 212, 247, 1)",
    "color-bg-interactive-subdued-hover": "rgba(232, 240, 253, 1)",
    "color-bg-interactive-subdued": "rgba(240, 245, 253, 1)",
    "color-bg-interactive-selected": "rgba(240, 245, 253, 1)",
    "color-bg-warning": "rgba(250, 201, 168, 1)",
    "color-bg-magic-strong": "rgba(121, 69, 227, 1)",
    "color-bg-magic-hover": "rgba(226, 214, 250, 1)",
    "color-bg-magic-active": "rgba(203, 180, 248, 1)",
    "color-bg-magic": "rgba(236, 227, 253, 1)",
    "color-bg-magic-subdued-hover": "rgba(236, 227, 253, 1)",
    "color-bg-magic-subdued-active": "rgba(226, 214, 250, 1)",
    "color-bg-magic-subdued": "rgba(242, 237, 253, 1)",
    "color-border-input-hover": "rgba(97, 106, 117, 1)",
    "color-border-inverse": "rgba(97, 106, 117, 1)",
    "color-border-strong-hover": "rgba(135, 144, 155, 1)",
    "color-border-input": "rgba(171, 177, 186, 1)",
    "color-border-hover": "rgba(171, 177, 186, 1)",
    "color-border-strong": "rgba(171, 177, 186, 1)",
    "color-border": "rgba(202, 206, 211, 1)",
    "color-border-disabled": "rgba(221, 224, 228, 1)",
    "color-border-subdued": "rgba(221, 224, 228, 1)",
    "color-border-interactive-disabled": "rgba(221, 224, 228, 1)",
    "color-border-primary": "rgba(0, 122, 92, 1)",
    "color-border-success": "rgba(22, 166, 121, 1)",
    "color-border-success-subdued": "rgba(80, 220, 169, 1)",
    "color-border-critical-active": "rgba(67, 14, 4, 1)",
    "color-border-critical-hover": "rgba(115, 24, 7, 1)",
    "color-border-critical": "rgba(197, 40, 12, 1)",
    "color-border-critical-subdued": "rgba(245, 107, 82, 1)",
    "color-border-caution": "rgba(216, 155, 13, 1)",
    "color-border-caution-subdued": "rgba(245, 196, 82, 1)",
    "color-border-info": "rgba(59, 195, 211, 1)",
    "color-border-info-subdued": "rgba(109, 211, 222, 1)",
    "color-border-interactive-active": "rgba(14, 53, 108, 1)",
    "color-border-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-border-interactive": "rgba(62, 125, 213, 1)",
    "color-border-interactive-focus": "rgba(62, 125, 213, 1)",
    "color-border-interactive-subdued": "rgba(187, 212, 247, 1)",
    "color-border-magic-strong": "rgba(121, 69, 227, 1)",
    "color-border-magic": "rgba(173, 139, 241, 1)",
    "color-icon-hover": "rgba(31, 33, 36, 1)",
    "color-icon": "rgba(97, 106, 117, 1)",
    "color-icon-active": "rgba(31, 33, 36, 1)",
    "color-icon-subdued": "rgba(135, 144, 155, 1)",
    "color-icon-disabled": "rgba(171, 177, 186, 1)",
    "color-icon-interactive-disabled": "rgba(171, 177, 186, 1)",
    "color-icon-inverse": "rgba(221, 224, 228, 1)",
    "color-icon-on-color": "rgba(255, 255, 255, 1)",
    "color-icon-primary": "rgba(0, 122, 92, 1)",
    "color-icon-success": "rgba(22, 166, 121, 1)",
    "color-icon-critical": "rgba(197, 40, 12, 1)",
    "color-icon-caution": "rgba(183, 126, 11, 1)",
    "color-icon-info": "rgba(42, 172, 187, 1)",
    "color-icon-warning": "rgba(242, 117, 34, 1)",
    "color-icon-interactive-active": "rgba(14, 53, 108, 1)",
    "color-icon-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-icon-interactive": "rgba(36, 99, 188, 1)",
    "color-icon-interactive-inverse": "rgba(102, 153, 225, 1)",
    "color-icon-magic": "rgba(121, 69, 227, 1)",
    "color-text": "rgba(31, 33, 36, 1)",
    "color-text-subdued": "rgba(97, 106, 117, 1)",
    "color-text-disabled": "rgba(135, 144, 155, 1)",
    "color-text-interactive-disabled": "rgba(135, 144, 155, 1)",
    "color-text-inverse-subdued": "rgba(171, 177, 186, 1)",
    "color-text-inverse": "rgba(241, 242, 244, 1)",
    "color-text-on-color": "rgba(255, 255, 255, 1)",
    "color-text-success-strong": "rgba(12, 59, 47, 1)",
    "color-text-success": "rgba(0, 122, 92, 1)",
    "color-text-primary": "rgba(0, 122, 92, 1)",
    "color-text-primary-hover": "rgba(18, 84, 67, 1)",
    "color-text-critical-strong": "rgba(67, 14, 4, 1)",
    "color-text-critical-active": "rgba(115, 24, 7, 1)",
    "color-text-critical": "rgba(197, 40, 12, 1)",
    "color-text-caution-strong": "rgba(77, 46, 5, 1)",
    "color-text-caution": "rgba(135, 92, 8, 1)",
    "color-text-info-strong": "rgba(16, 65, 71, 1)",
    "color-text-info": "rgba(32, 130, 141, 1)",
    "color-text-warning-strong": "rgba(77, 36, 5, 1)",
    "color-text-interactive-active": "rgba(14, 53, 108, 1)",
    "color-text-interactive-hover": "rgba(20, 73, 149, 1)",
    "color-text-interactive": "rgba(36, 99, 188, 1)",
    "color-text-interactive-inverse": "rgba(102, 153, 225, 1)",
    "color-text-magic-strong": "rgba(49, 13, 120, 1)",
    "color-text-magic": "rgba(90, 36, 205, 1)"
  },
  "colors": {
    "background": "rgba(246, 246, 247, 1)",
    "background-hovered": "rgba(241, 242, 243, 1)",
    "background-pressed": "rgba(237, 238, 239, 1)",
    "background-selected": "rgba(237, 238, 239, 1)",
    "surface": "rgba(255, 255, 255, 1)",
    "surface-dark": "rgba(32, 33, 35, 1)",
    "surface-neutral": "rgba(228, 229, 231, 1)",
    "surface-neutral-hovered": "rgba(219, 221, 223, 1)",
    "surface-neutral-pressed": "rgba(201, 204, 208, 1)",
    "surface-neutral-disabled": "rgba(241, 242, 243, 1)",
    "surface-neutral-subdued": "rgba(246, 246, 247, 1)",
    "surface-neutral-subdued-dark": "rgba(68, 71, 74, 1)",
    "surface-subdued": "rgba(250, 251, 251, 1)",
    "surface-disabled": "rgba(250, 251, 251, 1)",
    "surface-hovered": "rgba(246, 246, 247, 1)",
    "surface-hovered-dark": "rgba(47, 49, 51, 1)",
    "surface-pressed": "rgba(241, 242, 243, 1)",
    "surface-pressed-dark": "rgba(62, 64, 67, 1)",
    "surface-depressed": "rgba(237, 238, 239, 1)",
    "surface-search-field": "rgba(241, 242, 243, 1)",
    "surface-search-field-dark": "rgba(47, 49, 51, 1)",
    "backdrop": "rgba(0, 0, 0, 0.5)",
    "overlay": "rgba(255, 255, 255, 0.5)",
    "shadow-color-picker": "rgba(0, 0, 0, 0.5)",
    "shadow-color-picker-dragger": "rgba(33, 43, 54, 0.32)",
    "hint-from-direct-light": "rgba(0, 0, 0, 0.15)",
    "border": "rgba(140, 145, 150, 1)",
    "border-on-dark": "rgba(80, 83, 86, 1)",
    "border-neutral-subdued": "rgba(186, 191, 195, 1)",
    "border-hovered": "rgba(153, 158, 164, 1)",
    "border-disabled": "rgba(210, 213, 216, 1)",
    "border-subdued": "rgba(201, 204, 207, 1)",
    "border-depressed": "rgba(87, 89, 89, 1)",
    "border-shadow": "rgba(174, 180, 185, 1)",
    "border-shadow-subdued": "rgba(186, 191, 196, 1)",
    "divider": "rgba(225, 227, 229, 1)",
    "divider-dark": "rgba(69, 71, 73, 1)",
    "icon": "rgba(92, 95, 98, 1)",
    "icon-on-dark": "rgba(166, 172, 178, 1)",
    "icon-hovered": "rgba(26, 28, 29, 1)",
    "icon-pressed": "rgba(68, 71, 74, 1)",
    "icon-disabled": "rgba(186, 190, 195, 1)",
    "icon-subdued": "rgba(140, 145, 150, 1)",
    "text": "rgba(32, 34, 35, 1)",
    "text-on-dark": "rgba(227, 229, 231, 1)",
    "text-disabled": "rgba(140, 145, 150, 1)",
    "text-subdued": "rgba(109, 113, 117, 1)",
    "text-subdued-on-dark": "rgba(153, 159, 164, 1)",
    "interactive": "rgba(44, 110, 203, 1)",
    "interactive-on-dark": "rgba(54, 163, 255, 1)",
    "interactive-disabled": "rgba(189, 193, 204, 1)",
    "interactive-hovered": "rgba(31, 81, 153, 1)",
    "interactive-pressed": "rgba(16, 50, 98, 1)",
    "interactive-pressed-on-dark": "rgba(136, 188, 255, 1)",
    "focused": "rgba(69, 143, 255, 1)",
    "surface-selected": "rgba(242, 247, 254, 1)",
    "surface-selected-hovered": "rgba(237, 244, 254, 1)",
    "surface-selected-pressed": "rgba(229, 239, 253, 1)",
    "icon-on-interactive": "rgba(255, 255, 255, 1)",
    "text-on-interactive": "rgba(255, 255, 255, 1)",
    "action-secondary": "rgba(255, 255, 255, 1)",
    "action-secondary-disabled": "rgba(255, 255, 255, 1)",
    "action-secondary-hovered": "rgba(246, 246, 247, 1)",
    "action-secondary-hovered-dark": "rgba(84, 87, 91, 1)",
    "action-secondary-pressed": "rgba(241, 242, 243, 1)",
    "action-secondary-pressed-dark": "rgba(96, 100, 103, 1)",
    "action-secondary-depressed": "rgba(109, 113, 117, 1)",
    "action-primary": "rgba(0, 128, 96, 1)",
    "action-primary-disabled": "rgba(241, 241, 241, 1)",
    "action-primary-hovered": "rgba(0, 110, 82, 1)",
    "action-primary-pressed": "rgba(0, 94, 70, 1)",
    "action-primary-depressed": "rgba(0, 61, 44, 1)",
    "icon-on-primary": "rgba(255, 255, 255, 1)",
    "text-on-primary": "rgba(255, 255, 255, 1)",
    "text-primary": "rgba(0, 123, 92, 1)",
    "text-primary-hovered": "rgba(0, 108, 80, 1)",
    "text-primary-pressed": "rgba(0, 92, 68, 1)",
    "surface-primary-selected": "rgba(241, 248, 245, 1)",
    "surface-primary-selected-hovered": "rgba(179, 208, 195, 1)",
    "surface-primary-selected-pressed": "rgba(162, 188, 176, 1)",
    "border-critical": "rgba(253, 87, 73, 1)",
    "border-critical-subdued": "rgba(224, 179, 178, 1)",
    "border-critical-disabled": "rgba(255, 167, 163, 1)",
    "icon-critical": "rgba(215, 44, 13, 1)",
    "surface-critical": "rgba(254, 211, 209, 1)",
    "surface-critical-subdued": "rgba(255, 244, 244, 1)",
    "surface-critical-subdued-hovered": "rgba(255, 240, 240, 1)",
    "surface-critical-subdued-pressed": "rgba(255, 233, 232, 1)",
    "surface-critical-subdued-depressed": "rgba(254, 188, 185, 1)",
    "text-critical": "rgba(215, 44, 13, 1)",
    "action-critical": "rgba(216, 44, 13, 1)",
    "action-critical-disabled": "rgba(241, 241, 241, 1)",
    "action-critical-hovered": "rgba(188, 34, 0, 1)",
    "action-critical-pressed": "rgba(162, 27, 0, 1)",
    "action-critical-depressed": "rgba(108, 15, 0, 1)",
    "icon-on-critical": "rgba(255, 255, 255, 1)",
    "text-on-critical": "rgba(255, 255, 255, 1)",
    "interactive-critical": "rgba(216, 44, 13, 1)",
    "interactive-critical-disabled": "rgba(253, 147, 141, 1)",
    "interactive-critical-hovered": "rgba(205, 41, 12, 1)",
    "interactive-critical-pressed": "rgba(103, 15, 3, 1)",
    "border-warning": "rgba(185, 137, 0, 1)",
    "border-warning-subdued": "rgba(225, 184, 120, 1)",
    "icon-warning": "rgba(185, 137, 0, 1)",
    "surface-warning": "rgba(255, 215, 157, 1)",
    "surface-warning-subdued": "rgba(255, 245, 234, 1)",
    "surface-warning-subdued-hovered": "rgba(255, 242, 226, 1)",
    "surface-warning-subdued-pressed": "rgba(255, 235, 211, 1)",
    "text-warning": "rgba(145, 106, 0, 1)",
    "border-highlight": "rgba(68, 157, 167, 1)",
    "border-highlight-subdued": "rgba(152, 198, 205, 1)",
    "icon-highlight": "rgba(0, 160, 172, 1)",
    "surface-highlight": "rgba(164, 232, 242, 1)",
    "surface-highlight-subdued": "rgba(235, 249, 252, 1)",
    "surface-highlight-subdued-hovered": "rgba(228, 247, 250, 1)",
    "surface-highlight-subdued-pressed": "rgba(213, 243, 248, 1)",
    "text-highlight": "rgba(52, 124, 132, 1)",
    "border-success": "rgba(0, 164, 124, 1)",
    "border-success-subdued": "rgba(149, 201, 180, 1)",
    "icon-success": "rgba(0, 127, 95, 1)",
    "surface-success": "rgba(174, 233, 209, 1)",
    "surface-success-subdued": "rgba(241, 248, 245, 1)",
    "surface-success-subdued-hovered": "rgba(236, 246, 241, 1)",
    "surface-success-subdued-pressed": "rgba(226, 241, 234, 1)",
    "text-success": "rgba(0, 128, 96, 1)",
    "icon-attention": "rgba(138, 97, 22, 1)",
    "surface-attention": "rgba(255, 234, 138, 1)",
    "decorative-one-icon": "rgba(126, 87, 0, 1)",
    "decorative-one-surface": "rgba(255, 201, 107, 1)",
    "decorative-one-text": "rgba(61, 40, 0, 1)",
    "decorative-two-icon": "rgba(175, 41, 78, 1)",
    "decorative-two-surface": "rgba(255, 196, 176, 1)",
    "decorative-two-text": "rgba(73, 11, 28, 1)",
    "decorative-three-icon": "rgba(0, 109, 65, 1)",
    "decorative-three-surface": "rgba(146, 230, 181, 1)",
    "decorative-three-text": "rgba(0, 47, 25, 1)",
    "decorative-four-icon": "rgba(0, 106, 104, 1)",
    "decorative-four-surface": "rgba(145, 224, 214, 1)",
    "decorative-four-text": "rgba(0, 45, 45, 1)",
    "decorative-five-icon": "rgba(174, 43, 76, 1)",
    "decorative-five-surface": "rgba(253, 201, 208, 1)",
    "decorative-five-text": "rgba(79, 14, 31, 1)"
  },
  "depth": {
    "shadow-transparent": "0 0 0 0 transparent",
    "shadow-faint": "0 1px 0 0 rgba(22, 29, 37, 0.05)",
    "shadow-base": "0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)",
    "shadow-deep": "0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08)",
    "shadow-button": "0 1px 0 rgba(0, 0, 0, 0.05)",
    "shadow-top-bar": "0 2px 2px -1px rgba(0, 0, 0, 0.15)",
    "shadow-card": "0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15)",
    "shadow-popover": "0 3px 6px -3px rgba(23, 24, 24, 0.08), 0 8px 20px -4px rgba(23, 24, 24, 0.12)",
    "shadow-layer": "0 31px 41px 0 rgba(32, 42, 53, 0.2), 0 2px 16px 0 rgba(32, 42, 54, 0.08)",
    "shadow-modal": "0 26px 80px rgba(0, 0, 0, 0.2), 0 0px 1px rgba(0, 0, 0, 0.2)",
    "shadows-inset-button": "inset 0 -1px 0 rgba(0, 0, 0, 0.2)",
    "shadows-inset-button-pressed": "inset 0 1px 0 rgba(0, 0, 0, 0.15)"
  },
  "font": {
    "font-family-sans": "-apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
    "font-family-mono": "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace",
    "font-size-75": "0.75rem",
    "font-size-100": "0.875rem",
    "font-size-200": "1rem",
    "font-size-300": "1.25rem",
    "font-size-400": "1.5rem",
    "font-size-500": "1.75rem",
    "font-size-600": "2rem",
    "font-size-700": "2.5rem",
    "font-weight-regular": "400",
    "font-weight-medium": "500",
    "font-weight-semibold": "600",
    "font-weight-bold": "700",
    "font-line-height-1": "1rem",
    "font-line-height-2": "1.25rem",
    "font-line-height-3": "1.5rem",
    "font-line-height-4": "1.75rem",
    "font-line-height-5": "2rem",
    "font-line-height-6": "2.5rem",
    "font-line-height-7": "3rem"
  },
  "legacy": {
    "override-loading-z-index": "514",
    "choice-size": "1.25rem",
    "icon-size-small": "0.5rem",
    "icon-size-medium": "1.25rem",
    "choice-margin": "0.0625rem",
    "control-border-width": "0.125rem",
    "banner-border-default": "inset 0 0.0625rem 0 0 var(--p-color-border-strong), inset 0 0 0 0.0625rem var(--p-color-border-strong)",
    "banner-border-success": "inset 0 0.0625rem 0 0 var(--p-color-border-success-subdued), inset 0 0 0 0.0625rem var(--p-color-border-success-subdued)",
    "banner-border-highlight": "inset 0 0.0625rem 0 0 var(--p-color-border-info-subdued), inset 0 0 0 0.0625rem var(--p-color-border-info-subdued)",
    "banner-border-warning": "inset 0 0.0625rem 0 0 var(--p-color-border-caution-subdued), inset 0 0 0 0.0625rem var(--p-color-border-caution-subdued)",
    "banner-border-critical": "inset 0 0.0625rem 0 0 var(--p-color-border-critical-subdued), inset 0 0 0 0.0625rem var(--p-color-border-critical-subdued)",
    "thin-border-subdued": "0.0625rem solid var(--p-color-border-subdued)",
    "text-field-spinner-offset": "0.125rem",
    "text-field-focus-ring-offset": "-0.25rem",
    "button-group-item-spacing": "-0.0625rem",
    "range-slider-thumb-size-base": "1rem",
    "range-slider-thumb-size-active": "1.5rem",
    "frame-offset": "0rem"
  },
  "motion": {
    "duration-0": "0ms",
    "duration-50": "50ms",
    "duration-100": "100ms",
    "duration-150": "150ms",
    "duration-200": "200ms",
    "duration-250": "250ms",
    "duration-300": "300ms",
    "duration-350": "350ms",
    "duration-400": "400ms",
    "duration-450": "450ms",
    "duration-500": "500ms",
    "duration-5000": "5000ms",
    "ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
    "ease-in": "cubic-bezier(0.42, 0, 1, 1)",
    "ease-out": "cubic-bezier(0, 0, 0.58, 1)",
    "ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
    "linear": "cubic-bezier(0, 0, 1, 1)",
    "keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
    "keyframes-fade-in": "{ to { opacity: 1 } }",
    "keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
    "keyframes-spin": "{ to { transform: rotate(1turn) } }",
    "keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
    "keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }",
    "motion-duration-0": "0ms",
    "motion-duration-50": "50ms",
    "motion-duration-100": "100ms",
    "motion-duration-150": "150ms",
    "motion-duration-200": "200ms",
    "motion-duration-250": "250ms",
    "motion-duration-300": "300ms",
    "motion-duration-350": "350ms",
    "motion-duration-400": "400ms",
    "motion-duration-450": "450ms",
    "motion-duration-500": "500ms",
    "motion-duration-5000": "5000ms",
    "motion-ease": "cubic-bezier(0.25, 0.1, 0.25, 1)",
    "motion-ease-in": "cubic-bezier(0.42, 0, 1, 1)",
    "motion-ease-out": "cubic-bezier(0, 0, 0.58, 1)",
    "motion-ease-in-out": "cubic-bezier(0.42, 0, 0.58, 1)",
    "motion-linear": "cubic-bezier(0, 0, 1, 1)",
    "motion-keyframes-bounce": "{ from, 65%, 85% { transform: scale(1) } 75% { transform: scale(0.85) } 82.5% { transform: scale(1.05) } }",
    "motion-keyframes-fade-in": "{ to { opacity: 1 } }",
    "motion-keyframes-pulse": "{ from, 75% { transform: scale(0.85); opacity: 1; } to { transform: scale(2.5); opacity: 0; } }",
    "motion-keyframes-spin": "{ to { transform: rotate(1turn) } }",
    "motion-keyframes-appear-above": "{ from { transform: translateY(var(--p-space-1)); opacity: 0; } to { transform: none; opacity: 1; } }",
    "motion-keyframes-appear-below": "{ from { transform: translateY(calc(var(--p-space-1) * -1)); opacity: 0; } to { transform: none; opacity: 1; } }"
  },
  "shadow": {
    "shadow-inset-lg": "inset 0rem 0rem 0.4375rem 0.125rem rgba(31, 33, 36, 0.18)",
    "shadow-inset-md": "inset 0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.32)",
    "shadow-inset-sm": "inset 0rem 0rem 0.1875rem rgba(31, 33, 36, 0.56)",
    "shadow-none": "none",
    "shadow-xs": "0rem 0rem 0.125rem rgba(31, 33, 36, 0.24)",
    "shadow-sm": "0rem 0.0625rem 0.0625rem rgba(31, 33, 36, 0.1)",
    "shadow-md": "0rem 0.125rem 0.25rem rgba(31, 33, 36, 0.1), 0rem 0.0625rem 0.375rem rgba(31, 33, 36, 0.05)",
    "shadow-lg": "0rem 0.25rem 0.75rem rgba(31, 33, 36, 0.2), 0rem 0.125rem 0.375rem rgba(31, 33, 36, 0.05)",
    "shadow-xl": "0rem 0.25rem 1.125rem -0.125rem rgba(31, 33, 36, 0.08), 0rem 0.75rem 1.125rem -0.125rem rgba(31, 33, 36, 0.15)",
    "shadow-2xl": "0rem 2rem 2rem rgba(31, 33, 36, 0.15), 0rem 2rem 3.5rem -0.125rem rgba(31, 33, 36, 0.16)"
  },
  "shape": {
    "border-radius-05": "0.125rem",
    "border-radius-1": "0.25rem",
    "border-radius-2": "0.5rem",
    "border-radius-3": "0.75rem",
    "border-radius-4": "1rem",
    "border-radius-5": "1.25rem",
    "border-radius-6": "1.875rem",
    "border-radius-full": "624.9375rem",
    "border-radius-base": "0.1875rem",
    "border-radius-large": "0.375rem",
    "border-radius-half": "50%",
    "border-width-1": "0.0625rem",
    "border-width-2": "0.125rem",
    "border-width-3": "0.1875rem",
    "border-width-4": "0.25rem",
    "border-width-5": "0.3125rem",
    "border-base": "var(--p-border-width-1) solid var(--p-color-border-subdued)",
    "border-dark": "var(--p-border-width-1) solid var(--p-color-border)",
    "border-transparent": "var(--p-border-width-1) solid transparent",
    "border-divider": "var(--p-border-width-1) solid var(--p-color-border-subdued)",
    "border-divider-on-dark": "var(--p-border-width-1) solid var(--p-color-border-inverse)"
  },
  "spacing": {
    "space-0": "0",
    "space-025": "0.0625rem",
    "space-05": "0.125rem",
    "space-1": "0.25rem",
    "space-2": "0.5rem",
    "space-3": "0.75rem",
    "space-4": "1rem",
    "space-5": "1.25rem",
    "space-6": "1.5rem",
    "space-8": "2rem",
    "space-10": "2.5rem",
    "space-12": "3rem",
    "space-16": "4rem",
    "space-20": "5rem",
    "space-24": "6rem",
    "space-28": "7rem",
    "space-32": "8rem"
  },
  "space": {
    "space-0": "0",
    "space-025": "0.0625rem",
    "space-05": "0.125rem",
    "space-1": "0.25rem",
    "space-2": "0.5rem",
    "space-3": "0.75rem",
    "space-4": "1rem",
    "space-5": "1.25rem",
    "space-6": "1.5rem",
    "space-8": "2rem",
    "space-10": "2.5rem",
    "space-12": "3rem",
    "space-16": "4rem",
    "space-20": "5rem",
    "space-24": "6rem",
    "space-28": "7rem",
    "space-32": "8rem"
  },
  "zIndex": {
    "z-index-1": "100",
    "z-index-2": "400",
    "z-index-3": "510",
    "z-index-4": "512",
    "z-index-5": "513",
    "z-index-6": "514",
    "z-index-7": "515",
    "z-index-8": "516",
    "z-index-9": "517",
    "z-index-10": "518",
    "z-index-11": "519",
    "z-index-12": "520",
    "z-1": "100",
    "z-2": "400",
    "z-3": "510",
    "z-4": "512",
    "z-5": "513",
    "z-6": "514",
    "z-7": "515",
    "z-8": "516",
    "z-9": "517",
    "z-10": "518",
    "z-11": "519",
    "z-12": "520"
  }
};

export { border, breakpoints, color, colors, depth, font, legacy, motion, shadow, shape, space, spacing, tokens, zIndex };
