var styles = {
  "Cell": "Polaris-Grid-Cell",
  "Cell-1-column-xs": "Polaris-Grid-Cell--cell_1ColumnXs",
  "Cell-2-column-xs": "Polaris-Grid-Cell--cell_2ColumnXs",
  "Cell-3-column-xs": "Polaris-Grid-Cell--cell_3ColumnXs",
  "Cell-4-column-xs": "Polaris-Grid-Cell--cell_4ColumnXs",
  "Cell-5-column-xs": "Polaris-Grid-Cell--cell_5ColumnXs",
  "Cell-6-column-xs": "Polaris-Grid-Cell--cell_6ColumnXs",
  "Cell-1-column-sm": "Polaris-Grid-Cell--cell_1ColumnSm",
  "Cell-2-column-sm": "Polaris-Grid-Cell--cell_2ColumnSm",
  "Cell-3-column-sm": "Polaris-Grid-Cell--cell_3ColumnSm",
  "Cell-4-column-sm": "Polaris-Grid-Cell--cell_4ColumnSm",
  "Cell-5-column-sm": "Polaris-Grid-Cell--cell_5ColumnSm",
  "Cell-6-column-sm": "Polaris-Grid-Cell--cell_6ColumnSm",
  "Cell-1-column-md": "Polaris-Grid-Cell--cell_1ColumnMd",
  "Cell-2-column-md": "Polaris-Grid-Cell--cell_2ColumnMd",
  "Cell-3-column-md": "Polaris-Grid-Cell--cell_3ColumnMd",
  "Cell-4-column-md": "Polaris-Grid-Cell--cell_4ColumnMd",
  "Cell-5-column-md": "Polaris-Grid-Cell--cell_5ColumnMd",
  "Cell-6-column-md": "Polaris-Grid-Cell--cell_6ColumnMd",
  "Cell-1-column-lg": "Polaris-Grid-Cell--cell_1ColumnLg",
  "Cell-2-column-lg": "Polaris-Grid-Cell--cell_2ColumnLg",
  "Cell-3-column-lg": "Polaris-Grid-Cell--cell_3ColumnLg",
  "Cell-4-column-lg": "Polaris-Grid-Cell--cell_4ColumnLg",
  "Cell-5-column-lg": "Polaris-Grid-Cell--cell_5ColumnLg",
  "Cell-6-column-lg": "Polaris-Grid-Cell--cell_6ColumnLg",
  "Cell-7-column-lg": "Polaris-Grid-Cell--cell_7ColumnLg",
  "Cell-8-column-lg": "Polaris-Grid-Cell--cell_8ColumnLg",
  "Cell-9-column-lg": "Polaris-Grid-Cell--cell_9ColumnLg",
  "Cell-10-column-lg": "Polaris-Grid-Cell--cell_10ColumnLg",
  "Cell-11-column-lg": "Polaris-Grid-Cell--cell_11ColumnLg",
  "Cell-12-column-lg": "Polaris-Grid-Cell--cell_12ColumnLg",
  "Cell-1-column-xl": "Polaris-Grid-Cell--cell_1ColumnXl",
  "Cell-2-column-xl": "Polaris-Grid-Cell--cell_2ColumnXl",
  "Cell-3-column-xl": "Polaris-Grid-Cell--cell_3ColumnXl",
  "Cell-4-column-xl": "Polaris-Grid-Cell--cell_4ColumnXl",
  "Cell-5-column-xl": "Polaris-Grid-Cell--cell_5ColumnXl",
  "Cell-6-column-xl": "Polaris-Grid-Cell--cell_6ColumnXl",
  "Cell-7-column-xl": "Polaris-Grid-Cell--cell_7ColumnXl",
  "Cell-8-column-xl": "Polaris-Grid-Cell--cell_8ColumnXl",
  "Cell-9-column-xl": "Polaris-Grid-Cell--cell_9ColumnXl",
  "Cell-10-column-xl": "Polaris-Grid-Cell--cell_10ColumnXl",
  "Cell-11-column-xl": "Polaris-Grid-Cell--cell_11ColumnXl",
  "Cell-12-column-xl": "Polaris-Grid-Cell--cell_12ColumnXl"
};

export { styles as default };
